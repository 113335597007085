import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// import BoxFeature from "../components/box-feature";
// import BoxTab from "../components/box-tab";
// import Moduli from "../components/box-moduli";
// import SliderServizi from "../components/slider-servizi";
import Accordion from "../components/box-accordion";
import SliderReferenze from "../components/slider-referenze";

const Extranet = ({ data }) => {
  const { hero, extranet, referenze } = data;

  const referenzeExtranet = referenze.nodes.filter((plan) => plan.extranet);

  return (
    <Layout>
      <Seo title={`Intranet 80.20 Extranet | `} description={``} />

      <div className="page-product page-product--extranet">
        <div className="hero">
          <div className="container-lg">
            <div className="row">
              <div className="col-12 order-2 order-sm-1 col-sm-4 col-md-5 order-md-2">
                <GatsbyImage
                  image={getImage(hero)}
                  alt="img hero"
                  objectPosition="center center"
                  className="hero__img mb-xl-5"
                />
              </div>
              <div className="col-12 order-1 order-sm-2 col-sm-8 col-md-7">
                <div className="hero__claim hero__claim--extranet d-flex flex-column h-100">
                  <h1 className="hero__title">
                    Vuoi <strong>creare</strong> un portale per la tua rete distributiva?
                  </h1>
                  <div className="mt-auto text-md-end">
                    <h2>Intranet 80.20 è la soluzione</h2>
                    <h3 className="mb-0">
                      per comunicare e interagire con <br className="d-none d-xl-block" />
                      <strong className="color-highlighted">rivenditori, partner, negozi</strong>.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero__text-scroll hero__text-scroll--extranet">
            <div className="text-scroll text-scroll--primary" aria-hidden="true">
              <div className="text-scroll__wrap">
                <span className="text-uppercase extranet">Extranet</span>
              </div>
              <div className="text-scroll__wrap text-scroll__wrap--repeat">
                <span className="text-uppercase extranet">Extranet</span>
              </div>
            </div>
          </div>
        </div>

        {/* <BoxFeature items={extranet.feature} /> */}

        <div>
          <section id="intro" className="section">
            <div className="container-lg">
              <div className="row">
                <div className="col-12">
                  <div className="text-claim">
                    <p>
                      80.20, nella sua accezione “Extranet”, rappresenta un efficace{" "}
                      <strong>canale di comunicazione</strong> con le reti distributive delle
                      aziende, sfruttando le funzionalità tipiche già previste per il coinvolgimento
                      dei dipendenti.
                    </p>
                    <p>
                      Le extranet sono strumenti essenziali per le reti distributive in quanto
                      facilitano la comunicazione, migliorano la gestione delle operazioni e
                      consentono una collaborazione{" "}
                      <strong>più stretta con i punti sul territorio</strong>. Questo porta a un
                      maggiore controllo sulle operazioni di distribuzione e, alla fine, a una
                      migliore soddisfazione del cliente e a una maggiore redditività.
                    </p>
                    <p>
                      Le extranet svolgono un ruolo cruciale nell'ottimizzare le operazioni
                      all'interno delle reti di distributori, offrendo numerosi vantaggi e{" "}
                      <strong>migliorando l'efficienza complessiva</strong>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*
          <BoxTab />

          <Moduli items={extranet.moduli} plan="Intranet 80.20 Extranet?" />

          <SliderServizi
            items={extranet.servizi}
            claimText="<p>Potenzia la tua piattaforma Extranet:<br/>aggiungi quando vuoi nuove estensioni funzionali.</p>"
          />
          */}

          <section id="esigenze" className="section">
            <div className="container-lg">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <h2>
                    Cosa puoi fare con{" "}
                    <span className="color-highlighted">
                      Intranet 80.20 per le reti distributive
                    </span>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {extranet.cosafare.map((item, i) => (
                    <div key={i}>
                      <Accordion items={item} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>

        <SliderReferenze items={referenzeExtranet} title="Referenze Extranet" />
      </div>
    </Layout>
  );
};

export default Extranet;

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "extranet.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED)
      }
    }
    extranet: extranetJson {
      feature {
        name
        desc
      }
      servizi {
        name
        desc
        liferay
        microsoft
      }
      cosafare {
        name
        desc
      }
    }
    referenze: allContentfulReferenza(sort: { fields: date, order: DESC }) {
      nodes {
        ...ReferenzaFragment
      }
    }
  }
`;
